import CatalogModal from "../components/catalogs/CatalogModal";

export const TeacherCatalog = () => {
  return (
    <div className=" p-10">
        <CatalogModal/>
        
    </div>
  );
};
