
import React, { useState } from "react";
import TeacherActivities from "../components/TeacherActivities";

const ActivtiesPage: React.FC = () => {

  return (
    <div>
    <TeacherActivities/>
    </div>

  );
};

export default ActivtiesPage;
